<template>
    <div>
        <div class="mb-12 mb-lg-20">
            <v-row class="row--x-small">
                <v-spacer></v-spacer>
                <v-col cols="12" md="auto">
                    <v-select class="v-input--small w-100 w-md-100px" placeholder="선택" v-model="filter.searchKey" :items="filterItems" persistent-placeholder dense outlined hide-details item-text="text" item-valuie="value"></v-select>
                </v-col>
                <v-col cols="9" md="auto">
                    <v-text-field class="v-input--small w-100 w-md-240px" v-model="filter.searchValue" :disabled="filter.searchKey == null" dense outlined hide-details placeholder="검색어를 입력하세요."></v-text-field>
                </v-col>
                <v-col cols="3" md="auto">
                    <v-btn class="h-100 w-100 min-w-md-60px" color="grey-6" @click="search" dense><span class="white--text font-size-14">검색</span></v-btn>
                </v-col>
            </v-row>
        </div>

        <table class="board-list">
            <thead class="d-none d-lg-table-header">
                <tr>
                    <th style="width:56.6%;" class="board-list__txt">제목 </th>
                    <th style="width:16.7%;" class="board-list__txt">작성자</th>
                    <th style="width:16.7%;" class="board-list__txt">작성일</th>
                    <th style="width:10%;" class="board-list__txt">조회</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="board, idx in boards" :key="idx" @click="show(board)">
                    <td align="center" class="board-list__tit ellip">
                        {{board.subject}}
                    </td>
                    <td align="center" class="board-list__txt board-list__txt--first">
                        {{board.writer.name}}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{$dayjs(board.createdAt).format("YYYY-MM-DD")}}
                    </td>
                    <td align="center" class="board-list__txt">
                        {{board.viewCount}}
                    </td>
                </tr>
            </tbody>
        </table>

        <!-- <div>
            <v-row>
                <v-col v-for="board, idx in boards" :key="idx">
                    <div @click="show(board)">
                        <div>
                            <div class="w-100 pa-20 pa-md-30">
                                <v-row>
                                    <v-col>
                                        {{board.subject}}
                                    </v-col>
                                    <v-col>
                                        {{board.writer.name}}
                                    </v-col>
                                    <v-col>
                                        {{$dayjs(board.createdAt).format("YYYY-MM-DD")}}
                                    </v-col>
                                    <v-col>
                                        {{board.viewCount}}
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div> -->
        
        <div class="v-pagination-wrap">     
            <v-pagination v-model="page" :length="pageCount" @input="search()"/>
        </div>
    </div>
</template>

<script>
import api from "@/api";

export default {
    components:{
    },
    data() {
        return {
            filter:{
                code: "operation",
                searchKey: this.$route.query.searchKey || "",
                searchValue: this.$route.query.searchValue || "",
                isNotice: true
            },
            filterItems: [ "제목+내용", "제목", "내용" ],

            boards: [],
            page: 1,
            pageCount: 0,
			limit: 6,
            tab: null,
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        async init(){
            
                let { summary, boards } = await api.v1.boards.gets({ 
                        headers: { 
                            skip: (this.page - 1) * this.limit,
                            limit: this.limit,
                        },
                        params: { code: "operation" }
                    });
                this.pageCount = Math.ceil(summary.totalCount / this.limit);   
                this.boards = boards;

        },
        async search(){
            let { summary, boards } = await api.v1.boards.gets({ 
                    headers: { 
                        skip: (this.page - 1) * this.limit,
                        limit: this.limit,
                    },
                    params: this.filter
                });

            this.pageCount = Math.ceil(summary.totalCount / this.limit);   
            this.boards = boards;
            console.log(this.boards);
        },
        show(board) {
            this.$router.push({ path: `/company/operation/${board._id}`});
        },
    },

}
</script>

<style>

</style>