var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro', {
    attrs: {
      "view": _vm.$route.params._board
    }
  }), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [!_vm.$route.params._board ? _c('tab-intro') : _vm._e(), !_vm.$route.params._board ? _c('board-list') : _c('board-view')], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }