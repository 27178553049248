<template>

    <div>
        <table class="board-view">
            <tr>
                <th>{{subject}}</th>
            </tr>
            <tr>
                <td>
                    <div v-for="file, idx in files" :key="idx">
                        첨부파일: <span @click="download(`${file.url}`, `${file.fileName}`)" class="cursor-pointer">{{file.fileName}}</span>
                    </div> 
                </td>
            </tr>
            <tr>
                <td class="content_box">
                    <div v-html="content"></div> 
                </td>
            </tr>
        </table>
        <div class="d-flex justify-end mt-40 mt-lg-60">
            <v-btn @click="$router.go(-1)" outlined color="grey-9">목록보기</v-btn>
        </div>
    </div>
    
    <!-- <v-card>
        <v-card-text>
            <v-card-title>{{subject}}</v-card-title>
            <div v-html="content"></div> 
        </v-card-text>
    </v-card> -->
</template>

<script>
import api from "@/api";
import download from "downloadjs";

export default {
    computed:{
    },
    props:{

    },
    data() {
        return {
            subject: null,
            content: null,
            files: null
        }
    },
    mounted() {
        this.init()
    },
    methods:{
        async init() {
            let { board } = await api.v1.boards.get({ _id: this.$route.params._board });
            this.subject = board.subject;
            this.content = board.content;
            this.files = board.files;
            console.log(board);
        },
        download(path, rename){
            api.getResource(path).then(file => download(file, rename));
        }
    }
}
</script>

<style>

</style>