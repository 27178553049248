var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('table', {
    staticClass: "board-view"
  }, [_c('tr', [_c('th', [_vm._v(_vm._s(_vm.subject))])]), _c('tr', [_c('td', _vm._l(_vm.files, function (file, idx) {
    return _c('div', {
      key: idx
    }, [_vm._v(" 첨부파일: "), _c('span', {
      staticClass: "cursor-pointer",
      on: {
        "click": function ($event) {
          return _vm.download(`${file.url}`, `${file.fileName}`);
        }
      }
    }, [_vm._v(_vm._s(file.fileName))])]);
  }), 0)]), _c('tr', [_c('td', {
    staticClass: "content_box"
  }, [_c('div', {
    domProps: {
      "innerHTML": _vm._s(_vm.content)
    }
  })])])]), _c('div', {
    staticClass: "d-flex justify-end mt-40 mt-lg-60"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey-9"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v("목록보기")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }