<template>
    <client-page>

        <sub-visual-intro :view="$route.params._board" />

        <section class="section">
            <v-container>

                <tab-intro v-if="!$route.params._board" />

                <board-list v-if="!$route.params._board"/>
                <board-view v-else/>
                
            </v-container>
        </section>

    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
import api from "@/api";
import BoardView from "@/components/client/company/board-view.vue"
import BoardList from "@/components/client/company/board-list.vue"

export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
        BoardView,
        BoardList
    },
    data() {
        return {
            boards: null,
            filter:{
                code: this.$route.query.code,
                searchKey: this.$route.query.searchKey || "",
                searchValue: this.$route.query.searchValue || "",
                isNotice: true
            },
            filterItems: [ "제목+내용", "제목", "내용" ],
        }
    },
    mounted() {
        this.init();
    },
    methods:{
        async init() {
            let { boards } = await api.v1.boards.gets({ params: { code : "operation"}});
            this.boards = boards;
        },
        show(board) {
            this.$router.push({ path: `/company/${board._id}`});
        },
    }
};
</script>

<style lang="scss" scoped>
</style>